import React from "react";

import { Layout, SEO } from '../../../components/structure';
import { Content, Hero } from '../../../components/blocks';
import AccessibilitySectionLinks from "../../../components/blocks/sectionLinks"

const AccessibilityPage = () => (
  <Layout>
    <SEO title="Accessibility" />
    <Hero title={'Legal - Accessibility at Points'} />
    <Content>
    <div style={{width: '90%', maxWidth: '600px', margin: 'auto', marginBottom: '20px'}}>
      <AccessibilitySectionLinks />
      <h2 className={'kl'} style={{textAlign: 'left'}}>Questions?</h2>

      <p style={{textAlign: 'left'}}>For any questions about our Accessibility policies, please contact:</p>

      <address>Human Resources Department<br />
      111 Richmond St. W., Suite 700<br />
      Toronto, ON, M5H 2G4<br />
      Canada<br />
      </address>
      <div>Tel: 416-596-6370</div>
      <div>Fax: 416-596-3267</div>
      <div>Email: accessibility@points.com</div>
    </div>
    </Content>
  </Layout>
);

export default AccessibilityPage;
